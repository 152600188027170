:root {
    --body-text-color: #000;
    --primary-color: #187CAB;
    --primary-select: rgb(24 124 171 / 10%);
    --active-color: #A3B439;
    --active-select: rgb(163 180 57 / 10%);
    --table-header-bg: #F8F8F8;
    --table-text: #626367;
  }
  body{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: var(--body-text-color);
    background-color: #fff;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(193 193 193);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  
  h1{
    font-size: 32px;
    font-weight: normal;
    color: var(--primary-color);
    margin: 0;
    line-height: 1;
  }
  h2{
    font-size: 20px;
    font-weight: normal;
    color: var(--primary-color);
    margin-bottom: 0;
  }
  h3{
    font-size: 24px;
    font-weight: bold;
    color: #384148;
  }
  /* Custom CSS for DropdownTreeSelect to match other input fields */

  .react-dropdown-tree-select .dropdown {
    width: 100%;
  
  }
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow:after {
    content: "" !important;
    vertical-align: middle;
    color: #3c3c3c;
    float: right;
    height: 20px;
    width: 20px;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px 12px;
}

  .custom-dropdown-tree-select .dropdown-trigger {
    width: 100% !important;
    line-height: 1.5 !important;
    padding: 0.3rem 0.75rem !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 7px !important;
    color: #726F6E;
}

.custom-dropdown-tree-select .dropdown-trigger .tag-item + .tag-item input {
    display: none !important;
    border-bottom: 0 !important;
}
.custom-dropdown-tree-select .dropdown-trigger .tag-item{
    margin: 0 !important;
}
.custom-dropdown-tree-select .dropdown-trigger .tag-item .tag{
    background-color: var(--primary-color);
    border: 1px solid #e9e9e9;
    padding: 0px 4px 0px 4px;
    border-radius: 4px;
    display: inline-block;
    color: #fff;
    font-size: 13px;
}
.custom-dropdown-tree-select .dropdown-trigger .tag-item .tag .tag-remove {
    color: #fff;
    font-size: 100%;
    line-height: 1;
    margin-left: 7px;
    margin-right: 4px;
    display: inline-block;
    vertical-align: 1px;
}
.custom-dropdown-tree-select .dropdown-trigger .tag-item .search{
    border-bottom: none;
}

.custom-dropdown-tree-select .dropdown-content {
    width: 100% !important; 
}
.react-dropdown-tree-select .dropdown .dropdown-content ul{
    max-height : 300px;
    overflow-y: auto;
}

.custom-dropdown-tree-select .dropdown-item {
    line-height: 1.5 !important;
    padding: 0.375rem 0.75rem !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    top: 33px;
    border-radius: 7px;
}
/**** **/

  a {
      color: var(--primary-color);
      text-decoration: none;
      cursor: pointer;
  }
  hr {
      border-color: #707070;
      opacity: .6;
  }
  .btn{
    cursor: pointer;
  }
  .btn-primary{
    min-width: 146px;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color:#fff;
    border-radius: 8px;
    padding: 9px 15px;
  }
  .btn-primary:hover{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .btn-check:active+.btn-primary, 
  .btn-check:checked+.btn-primary, 
  .btn-primary.active, .btn-primary:active, 
  .show>.btn-primary.dropdown-toggle,
  .btn-check:focus+.btn-primary, 
  .btn-primary:focus,
  .btn-check:active+.btn-primary:focus, 
  .btn-check:checked+.btn-primary:focus, 
  .btn-primary.active:focus, 
  .btn-primary:active:focus, 
  .show>.btn-primary.dropdown-toggle:focus {
      color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: 0 0 0 0 rgb(49 132 253 / 50%);
  }
  .btn-secondary{
    min-width: 146px;
    background-color: #fff;
    border-color: var(--primary-color);
    color:var(--primary-color);
    border-radius: 8px;
    padding: 9px 15px;
  }
  .btn-secondary:hover{
    background-color: #fff;
    border-color: var(--primary-color);
    color:var(--primary-color);
  }
  .btn-primary.disabled, 
  .btn-primary:disabled {
      color: #fff;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
  }
  .btn-secondary.disabled, 
  .btn-secondary:disabled {
      color: var(--primary-color);
      background-color: #fff;
      border-color: var(--primary-color);
  }
  
  .btn-danger{
    min-width: 146px;
    border-radius: 8px;
    padding: 9px 15px;
  }
  .form-control{
    border-radius: 7px;
    border-color: #D1D1D1;
    color: #726F6E;
    padding: 12px;
    font-size: 1rem;
  }
  .form-select{
    border-radius: 7px;
    border-color: #D1D1D1;
    color: #726F6E;
    padding: 12px 2.25rem 12px 12px;
    font-size: 15px;
  }
  .input-group-text {
      border-color: #D1D1D1;
      border-radius: 10px;
      background-color: transparent;
  }
  .form-control:focus {
    border-color: #D1D1D1;
    color: #726F6E;
      outline: 0;
      box-shadow: 0 0 0 0 rgb(13 110 253 / 25%);
  }
  .card {
      border: 0;
      border-radius: 7px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  }
  .card-header {
      border-bottom: 0;
      background-color: transparent;
  }
  .card-body{
      padding-top: 0;
  }
  .dashboard-widgets .card-body{
      padding-top: 1rem;
  }
  .dashboard-widgets .card {
      margin-left: 15px;
      width: calc(100% - 15px);
  }
  .dashboard-widgets .card i {
      border: 0;
      border-radius: 7px;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      margin-left: 15px;
      width: calc(100% - 15px);
      background-color: #fff;
      height: 65px;
      width: 65px;
      margin: 0 0 0 -30px;
      display: inline-block;
      padding: 10px;
  }
  .dashboard-widgets .card i img{
      width: 44px;
      margin: auto;
  }
  .dashboard-widgets .card .widgets-content{
      width: calc(100% - 45px);
      display: inline-block;
      vertical-align: top;
      text-align: right;
  }
  .dashboard-widgets .card .widgets-content .widgets-amount{
      font-size: 50px;
      font-weight: bold;
      line-height: 1;
  }
  .dashboard-widgets .dashboard-widgets-1 .card i {
      background-color: #4A8CDA;
  }
  .dashboard-widgets .dashboard-widgets-1 .card .widgets-content .widgets-amount{
      color: #4A8CDA;
  }
  .dashboard-widgets .dashboard-widgets-2 .card i {
      background-color: #70B944;
  }
  .dashboard-widgets .dashboard-widgets-2 .card .widgets-content .widgets-amount{
      color: #70B944;
  }
  .dashboard-widgets .dashboard-widgets-3 .card i {
      background-color: #6647BF;
  }
  .dashboard-widgets .dashboard-widgets-3 .card .widgets-content .widgets-amount{
      color: #6647BF;
  }
  .dashboard-widgets .dashboard-widgets-4 .card i {
      background-color: var(--primary-color);
  }
  .dashboard-widgets .dashboard-widgets-4 .card .widgets-content .widgets-amount{
      color: var(--primary-color)
  }
  .dashboard-widgets .dashboard-widgets-5 .card i {
      background-color: #F27944;
  }
  .dashboard-widgets .dashboard-widgets-5 .card .widgets-content .widgets-amount{
      color: #F27944;
  }
  .table{
      margin-bottom: 0;
  }
  .table tr th,
  table.dataTable thead th, 
  table.dataTable thead td, 
  table.dataTable tfoot th, 
  table.dataTable tfoot td{
      font-weight: bold;
      text-align: center;
      background-color: var(--table-header-bg);
  }
  .table tr th,
  .table tr td{
      color: var(--table-text);
      font-size: 18px;
      border-bottom: 1px solid #CCCCCC;
      border-right:  1px solid #CCCCCC;
      vertical-align: middle;
  }
  .table tr th:last-child,
  .table tr td:last-child{
      border-right:  0;
  }
  .table>:not(:first-child) {
      border-top: 1px solid #CCCCCC;
  }
  .table-striped>tbody>tr:nth-of-type(odd)>*,
  table.dataTable.table-striped>tbody>tr.odd>* {
      --bs-table-accent-bg: transparent;
      box-shadow:unset;
      color: var(--table-text);
  }
  .table-striped>tbody>tr:nth-of-type(even)>*,
  table.dataTable.table-striped>tbody>tr.even>* {
      --bs-table-accent-bg: #FCFCFC;
      color: var(--table-text);
  }
  
  .btn-labeled.btn-primary {
      font-size: 20px;
      min-width: inherit;
      line-height: 1.6;
      font-style: normal;
      padding: 9px 30px 9px 15px;
  }
  .btn-label {
      position: relative;
      left: -15px;
      display: inline-block;
      padding: 6px 0;
      background: #384148;
      border-radius: 8px;
      border: 1px solid var(--primary-color);
      height: 50px;
      width: 50px;
      margin-right: 10px;
  }
  .btn-labeled.btn-primary:hover {
      background: #fff;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
  }
  .btn-labeled.btn-primary:hover .btn-label {
      background: var(--primary-color);
  }
  /*--Login-start--*/
  .login{
    min-height: 100dvh;
    width: 100%;
    background-color: #fff;
  }
  .login-box{
    background-color: transparent;
    width: 100%;
    min-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 30px 0;
    margin: auto;
  }
  .login-main{
    height: auto;
    max-width: 600px;
    width: 90%;
    background: #FCFCFB;
    padding: 25px 125px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.08);
    -moz-box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.08);
    box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.08);
  }
  
  .login-main .input-group>.form-control{
    border-left: 0;
    border-radius: 5px;
  }
  .login-main .form-label{
    font-size: 18px;
  }
  .login-main h1 {
    font-size: 24px;
    color: #333333;
  }
  .login-main .btn-primary {
    width: 100%;
  }
  .loginLogo img{
      width: 185px;
  }
  .login-main .input-group-text {
      border-radius: 5px;
      background: #fff;
  }
  
  /*--Login-end--*/
  
  /*--Dashboard-start--*/
  /*--header-start--*/
  header{
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    z-index: 77;
    position: relative;
  }
  .navbar-brand img{
      max-width: 140px;
      height: 70px;
      width: 100%;
  }
  .userProfile {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
  }
  .userPhoto{
    height: 49px;
    width: 49px;
    background-color:var(--primary-color);
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-radius: 100%;
    margin-right: 12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .userDetails .userName{
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .userDetails .userEmail{
    width: 100%;
    font-size: 11px; 
  }
  .userArrow{
    margin-left: 10px;
  }
  .userMenu .dropdown-menu{
      top: 85px;
      right: 0;
      left: inherit;
      border: 0;
      border-radius: 8px;
      -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 16%);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 16%);
  }
  .userMenu .dropdown-menu ul{
    list-style: none;
  }
  .userMenu .dropdown-menu:before {
      top: -20px;
      position: absolute;
      content: "";
      width: 21px;
      height: 5px;
      border-bottom: solid 20px rgb(255 255 255);
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 7;
  }
  .userMenu .dropdown-menu:after {
      top: -21px;
      position: absolute;
      content: "";
      width: 21px;
      height: 5px;
      border-bottom: solid 20px rgb(205 205 205);
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
      left: 50%;
      transform: translate(-50%, 0);
  }
  .userMenu .dropdown-menu .dropdown-item {
      text-align: center;
  }
  /*--header-end--*/
  /*--left-menu-start--*/
  .leftMenu {
      min-height: calc(100dvh - 83px);
      width: 250px;
      position: relative;
      z-index: 78;
  }
  .activeMenu .leftMenu {
      margin-left: -250px;
  }

  /*--left-menu-end--*/
  
  /*--Main Start--*/
.main {
    width: calc(100% - 250px);
    height: calc(100dvh - 115px);
    float: left;
    margin-top: 115px;
    overflow: auto;
    background-color: #faf9f9;
}
  .activeMenu .main {
    width: 100%;
  }
  .btn-view-all{
    font-size: 15px;
    line-height: 1;
      margin-top: 7px;
  }
  .btn-view-all:after{
      content: '';
      display: inline-block;
      width: 0px;
      height: 0px;
      margin-left: 7px;
      border-style: solid;
      border-width: 7.5px 0 7.5px 10.6px;
      border-color: transparent transparent transparent var(--primary-color);
      transform: rotate(0deg);
      vertical-align: top;
  }
  .dataTables_wrapper .row:first-child .col-sm-8:first-child > div {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0;
      margin-top: 5px;
  }
  .dataTables_wrapper .form-select,
  .page-item {
      padding: 5px 2.25rem 5px 7px;
  }
  .dataTables_wrapper .form-control,
  .table-search,
  .form-page{
      padding: 5px 7px;
  }
  .table-search{
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="%23187cab" height="20px" width="20px" version="1.1" id="Capa_1" viewBox="0 0 330 330" xml:space="preserve"><path d="M325.606,304.394L223.328,202.117c16.707-21.256,26.683-48.041,26.683-77.111C250.011,56.077,193.934,0,125.005,0 C56.077,0,0,56.077,0,125.006C0,193.933,56.077,250.01,125.005,250.01c29.07,0,55.855-9.975,77.11-26.681l102.278,102.277 C307.322,328.536,311.161,330,315,330s7.678-1.464,10.606-4.394C331.465,319.749,331.465,310.251,325.606,304.394z M30,125.006 C30,72.619,72.619,30,125.005,30c52.387,0,95.006,42.619,95.006,95.005c0,52.386-42.619,95.004-95.006,95.004 C72.619,220.01,30,177.391,30,125.006z"/></svg>');
    background-repeat: no-repeat;
    background-position: 5px;
    padding-left: 31px;
}
  .dataTables_wrapper .btn-group .btn-secondary{
      border: 0;
      padding: 0;
      min-width: inherit;
      margin-left: 10px !important;
  }
  .dataTables_wrapper .btn-group .btn-group{
      position: absolute;
      right: 0;
      top: -68px;
  }
  .dataTables_wrapper .btn-group .btn-group .dt-down-arrow,
  .dataTables_wrapper .btn-group .btn-group .btn:after{
      display: none;
  }
  div.dt-button-collection {
      left: inherit !important;
      right: 0;
  }
  div.dataTables_wrapper div.dataTables_paginate {
      display: inline-block;
  }
  .buttons .paginate_button{
      display: none;
  }
  .buttons .previous.paginate_button.disabled,
  .buttons .next.paginate_button.disabled{
      opacity: .5;
  }
  .buttons .previous.paginate_button,
  .buttons .next.paginate_button{
      display: block;
      position: relative;
      margin-left: 7px;
  }
  .buttons .previous.paginate_button a,
  .buttons .next.paginate_button a{
      text-indent: -99em;
      position: relative;
      overflow: hidden;
      opacity: 0;
      cursor: pointer;
  }
  .buttons .previous.paginate_button:after {
      content: '';
      display: inline-block;
      width: 0px;
      height: 0px;
      margin-left: 0;
      border-style: solid;
      border-width: 14.5px 0 14.5px 25.6px;
      border-color: transparent transparent transparent var(--primary-color);
      vertical-align: top;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(180deg);
      cursor: pointer;
  }
  .buttons .next.paginate_button:after {
      content: '';
      display: inline-block;
      width: 0px;
      height: 0px;
      margin-left: 0;
      border-style: solid;
      border-width: 14.5px 0 14.5px 25.6px;
      border-color: transparent transparent transparent var(--primary-color);
      vertical-align: top;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
      cursor: pointer;
  }
  .table-pagination .page-link {
      padding: 7px;
      color: var(--primary-color);
      line-height: .8;
  }
  .table-pagination .page-item.active .page-link {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
  }
  .dropdown-item.active, .dropdown-item:active {
      color: var(--bs-body-color);
      text-decoration: none;
      background-color: transparent;
  }
  
  div.dt-buttons .dt-button::before {
      position: absolute;
      display: block;
      content: "";
      left: 3px;
      top: 26%;
      width: 15px;
      height: 15px;
      border: solid var(--primary-color);
      border-width: 1px;
      opacity: 1;
      transition: all 0.2s ease;
      border-radius: 17px;
  }
  
  div.dt-buttons .dt-button::after {
      position: absolute;
      display: block;
      content: "";
      left: 10px;
      top: 47%;
      width: 4px;
      height: 8px;
      border: solid var(--primary-color);
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -moz-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      opacity: 0;
      transition: all 0.2s ease;
  }
  
  div.dt-buttons .dt-button.active::after {
      opacity: 1;
      transition: all 0.2s ease;
  }
  div.dt-button-collection .dt-button {
      border-radius: 0;
      padding-left: 25px;
      position: relative;
  }
  .btn-check:checked+.btn, 
  .btn.active, .btn.show, 
  .btn:first-child:active, 
  :not(.btn-check)+.btn:active {
      color:var(--primary-color);
      background-color:  #fff;
      border-color: var(--primary-color);
  }
  table.dataTable thead>tr>th.sorting:before, 
  table.dataTable thead>tr>th.sorting_asc:before, 
  table.dataTable thead>tr>th.sorting_desc:before, 
  table.dataTable thead>tr>th.sorting_asc_disabled:before, 
  table.dataTable thead>tr>th.sorting_desc_disabled:before, 
  table.dataTable thead>tr>td.sorting:before, 
  table.dataTable thead>tr>td.sorting_asc:before, 
  table.dataTable thead>tr>td.sorting_desc:before, 
  table.dataTable thead>tr>td.sorting_asc_disabled:before, 
  table.dataTable thead>tr>td.sorting_desc_disabled:before{
      width: 7px;
      height: 10px;
      content: '';
      background-image: url(../images/sortUp.svg);
  }
  table.dataTable thead>tr>th.sorting:after, 
  table.dataTable thead>tr>th.sorting_asc:after, 
  table.dataTable thead>tr>th.sorting_desc:after, 
  table.dataTable thead>tr>th.sorting_asc_disabled:after, 
  table.dataTable thead>tr>th.sorting_desc_disabled:after, 
  table.dataTable thead>tr>td.sorting:after, 
  table.dataTable thead>tr>td.sorting_asc:after, 
  table.dataTable thead>tr>td.sorting_desc:after, 
  table.dataTable thead>tr>td.sorting_asc_disabled:after, 
  table.dataTable thead>tr>td.sorting_desc_disabled:after{
      width: 7px;
      height: 10px;
      content: '';
      background-image: url(../images/sortDown.svg);
  }
  .btn-check:focus+.btn-secondary, .btn-secondary:focus {
      background-color: #fff;
      border-color: var(--primary-color);
      color: var(--primary-color);
      box-shadow: unset;
  }
  .btn:focus-visible,
  .btn-check:checked+.btn:focus-visible, 
  .btn.active:focus-visible, 
  .btn.show:focus-visible, 
  .btn:first-child:active:focus-visible, 
  :not(.btn-check)+.btn:active:focus-visible,
  .btn-check:active+.btn-secondary:focus, 
  .btn-check:checked+.btn-secondary:focus, 
  .btn-secondary.active:focus, 
  .btn-secondary:active:focus, 
  .show>.btn-secondary.dropdown-toggle:focus {
      box-shadow: unset;
  }
  .modal h5{
      color: var(--primary-color);
      font-size: 28px;
      line-height: 1;
  }
  .modal-header .btn-close{
      background-color: #F2F2F2;
      background-size: 12px;
  }
  .col-form-label {
      padding-top: 0;
  }
  .view-details .col-form-label {
      padding-bottom: 0;
      font-size: .9rem;
  }
  .view-details h6 {
      font-size: 1.1rem;
      font-weight: 600;
  }
  /* table.dataTable tbody>tr>td:first-child,
  table.dataTable thead>tr>th:first-child,
  div.dt-button-collection .dt-button:first-child{
      display: none;
  } */
  table.dataTable table tbody>tr>td:first-child,
  table.dataTable table thead>tr>th:first-child{
      display: block;
  }
  div.dataTables_wrapper div.dataTables_filter label {
      position: relative;
  }
  div.dataTables_wrapper div.dataTables_filter i{
      position: absolute;
      top: 10px;
      left: 18px;
      color: #187cab;
  }
  div.dataTables_wrapper div.dataTables_filter input,
  div.dataTables_wrapper div#COAmapping_filter.dataTables_filter input {
      padding-left: 32px;
  }
  
  .alertBox{
      margin: -24px -24px 0 -24px;
      border-radius: 0;
      display: none;
  }
  .select2-dropdown {
      z-index: 1111;
  }
  .col-md-12 .select2-container {
      width: 100% !important;
  }
  .select2-container--default .select2-selection--single {
      background-color: #fff;
      border: 1px solid #d1d1d1;
      border-radius: 7px;
      height: 50px;
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #444;
      line-height: 28px;
      padding: 10px 12px;
  }
  .multiSelect .btn-group{
      width: 100%;
      position: relative;
  }
  .multiSelect .open>.dropdown-menu {
      display: block;
      width: 100%;
      top: 100%;
  }
  .multiSelect .btn-group>.btn{
      border-radius: 7px;
      border-color: #D1D1D1;
      background-color: #fff;
      color: #726F6E;
      padding: 12px;
      font-size: 15px;
      width: 100%;
      text-align: left;
  }
  .multiSelect .multiselect-container>li,
  .multiSelect4 .multiselect-container>li {
      display: inline-block;
      width: 50%;
  }
  .multiSelect3 .multiselect-container>li {
      display: inline-block;
      width: 33%;
  }
  .multiSelect .multiselect-container>li>a>label {
      padding: 3px 20px 3px 10px;
      font-size: 14px;
  }
  .multiSelect .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0;
      vertical-align: 0;
      content: "";
      border-top: 0;
      border-right: 2px solid #383e44;
      border-bottom: 2px solid #383e44;
      border-left: 0;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 16px;
      top: 19px;
      transform: rotate(45deg);
  }
  .multiSelect .open>.dropdown-menu {
      display: block;
      width: 100%;
      top: 100%;
      height: 215px;
      overflow-y: scroll;
  }
  .table.roles tr th span{
      background: #F2F2F2;
      width: 100%;
      display: inline-block;
      border-radius: 8px;
      color: #292929;
      padding: 9px;
      border: 1px solid #E8E8E8;
  }
  .table.roles tr th.text-head span{
      background: var(--primary-color);
      color: #fff;
      border: 1px solid var(--primary-color);
  }
  .form-check-input {
      border-color: #707070;
  }
  .form-check-input:checked {
      background-color: #187cab;
      border-color: #187cab;
  }
  .table tr td .btn-primary,
  .table tr td .btn-secondary {
      min-width: 110px;
      padding: 3px 15px;
  }
  
  .report-widgets-body {
      display: flex;
      width: 100%;
      align-items: center;
  }
  .report-widgets-content {
      width: 100%;
  }
  .report-widgets-content label {
      width: 100%;
      font-size: 16px;
  }
  .report-widgets-content span {
      width: 100%;
      font-size: 20px;
      display: inline-block;
      font-weight: bold;
      line-height: 1;
  }
  
  .dragImages {
      position: relative;
      background-color: #F5F6FA;
      border: 1px solid #E1E1E1;
      padding: 15px;
      -webkit-box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 16%);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 16%);
  }
  .dragImagesBoder{
      padding: 50px;
      border-color: #C2CDDA;
      border-width: 2px;
      border-style: dashed;
  
  }
  #imgUpload {
      display: none;
  }
  .mappingTable.table tr th, 
  .mappingTable.table tr td {
      color: var(--table-text);
      font-size: 14px;
      border-bottom: 0;
      border-right: 0;
  }
  .mappingTable.table>:not(:first-child) {
      border-top: 0;
  }
  .mappingTable .form-control,
  .mappingTable .form-select {
      padding: 9px 2.25rem 9px 12px;
  }
  .mappingTable.table tr th {
      background-color: transparent;
  }
  .mappingTable.table-striped>tbody>tr:nth-of-type(odd)>* {
      background: #EEF6F9;
  }
  .mappingTable.table-striped>tbody>tr:nth-of-type(even)>* {
      background: #fff;
  }

  .ka-table tr th, 
  .ka-table tr td {
      color: var(--table-text);
      font-size: 18px;
      border-bottom: 0;
      border-right: 0;
      padding: 0.5rem 0.5rem;
      line-height: 1.2;
  }
  .ka-table>:not(:first-child) {
      border-top: 0;
  }
  .ka-table .form-control,
  .ka-table .form-select {
      padding: 9px 2.25rem 9px 12px;
  }
  .ka-table tr th {
      background-color: transparent;
  }
  .ka-table>tbody>tr:nth-of-type(odd)>* {
      background: #EEF6F9;
  }
  .ka-table>tbody>tr:nth-of-type(even)>* {
      background: #fff;
  }

  .dataTables_wrapper .row:nth-child(2){
      margin: 0;
  }
  .dataTables_wrapper .row:nth-child(2) .col-sm-12{
      padding: 0;
      overflow: auto;
  }
  #viewModal .modal-dialog {
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
  }
  .funds-mapping {
      max-width: 500px;
      }
  .funds-mapping ul{
      margin: 0;
      padding: 0;
      list-style: none;
  }
  .funds-mapping ul li{
      padding-left: 30px;
      position: relative;
      padding-bottom: 10px;
  }
  .funds-mapping ul ul{
      padding-top: 10px;
  }
  .funds-mapping ul li:before{
      content: '';
      display: inline-block;
      width: 30px;
      height: 1px;
      background: #707070;
      position: absolute;
      left: 0;
      top:21px;
      opacity: .6;
  }
  .funds-mapping ul li:after{
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      background: #707070;
      position: absolute;
      left: 0;
      top:21px;
      opacity: .6;
  }
  .funds-mapping ul li label{
      min-width: 146px;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: #fff;
      border-radius: 8px;
      padding: 9px 15px;
      width: 100%;
  }
  .funds-mapping ul li li{
      padding-left: 50px;
      position: relative;
  }
  .funds-mapping ul li li:before{
      width: 30px;
      left: 20px;
  }
  .funds-mapping ul li li:after {
      width: 1px;
      height: 52px;
      left: 20px;
      top: -30px;
  }
  .funds-mapping ul li li:first-child:after {
      height: 32px;
      top: -10px;
  }
  .funds-mapping > ul > li:last-child:after{
      display: none;
  }
  .funds-mapping ul li label span:before {
      content: '';
      display: inline-block;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 8.5px 0 8.5px 11px;
      border-color: transparent transparent transparent #fff;
      transform: rotate(90deg);
      vertical-align: middle;
      margin-right: 10px;
      transition: all .3s ease;
  }
  .funds-mapping ul li label span.collapsed:before {
      transform: rotate(0deg);
  }
  .fund-form .card{
      box-shadow: unset;
      border: 1px solid rgb(112 112 112 / 50%);
  }
  .fund-function a{
      margin-left: 7px;
  }
  .fund-function img{
      width: 12px;
  }
  .funds-mapping ul li:hover > label,
  .funds-mapping ul li.active > label {
      background-color: #009DE6;
      border-color: #009DE6;
  }
  .quality-investments ul{
      padding: 0;
      margin: 0;
      list-style: none;
  }
  .quality-investments ul li{
      text-align: center;
      position: relative;
  }
  .quality-investments ul li label{
      text-align: center;
      position: relative;
      padding-bottom: 60px;
      width: 100%;
      z-index: 777;
  }
  .quality-investments ul li label span{
      min-width: 210px;
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: #fff;
      border-radius: 8px;
      padding: 9px 15px;
      position: relative;
      z-index: 777;
      display: inline-block;
  }
  .quality-investments ul li label:before{
      content: '';
      display: inline-block;
      width: 50%;
      height: 30px;
      position: absolute;
      border-top:1px solid #707070;
      border-left:1px solid #707070;
      border-right:1px solid #707070;
      bottom:0;
      left: 50%;
      transform: translate(-50%,0);
  }
  .quality-investments ul li label:after{
      content: '';
      display: inline-block;
      width: 1px;
      height: 30px;
      position: absolute;
      border-left:1px solid #707070;
      bottom:30px;
      left: 50%;
      transform: translate(-50%,0);
  }
  .quality-investments ul.secondLevel li label span{
      background-color: #fff;
      border:1px solid var(--active-color);
      color: var(--active-color);
  }
  .quality-investments ul.thirdLevel li label span{
      background-color: var(--active-color);
      border:1px solid var(--active-color);
      color: #fff;
  }
  .quality-investments ul.thirdLevel li label:before,
  .quality-investments ul.thirdLevel li label:after{
      display: none;
  }
  .quality-investments ul.thirdLevel li label {
      padding-bottom: 30px;
  }
  .quality-investments ul.thirdLevel ul li label {
      margin-left: 42px;
  }
  .quality-investments ul.thirdLevel ul li label span{
      position: relative;
  }
  .quality-investments ul.thirdLevel ul li label span:before{
      content: '';
      display: inline-block;
      width: 32px;
      height: 80px;
      position: absolute;
      border-top:0;
      border-right:0;
      border-bottom:1px solid #707070;
      border-left:1px solid #707070;
      bottom: 20px;
      left: -32px;
      transform:unset;
  }
  .quality-investments ul.thirdLevel ul li label span {
      background-color: #fff;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
  }
  .quality-investments ul.thirdLevel ul{
      position: relative;
      z-index: 77;
  }
  .searchBox .form-control {
      border-radius: 77px;
      border-right: 0;
      padding-left: 20px;
  }
  .searchBox .btn-outline-secondary{
      border-color: #D1D1D1;
      border-left: 0;
      border-radius: 50px;
      padding-right: 20px;
  }
  .integrationsCenter.row{
      margin-left: -5px;
      margin-right: -5px;
  }
  .integrationsCenter .col{
      min-width: 20%;
      padding: 5px;
  }
  .integrationsCenter .col a{
      width: 100%;
      text-align: center;
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10);
      -moz-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10);
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.10);
      display: inline-block;
  }
  .integrationsCenter .card {
      box-shadow: unset;
      border: 1px solid rgb(112 112 112 / 50%);
  }
  .integrationsCenter .logo{
      width: 180px;
  }
  .small-label {
      position: relative;
  }
  .small-label span{
      position: absolute;
      background: #fff;
      line-height: 1;
      font-size: 12px;
      top: -5px;
      left: 24px;
  }
  
  
  /* .page-loader {
      width: 100%;
      height: 100dvh;
      position: fixed;
      background: #fff;
      z-index: 1000;
      top: 0;
      left: 0;
  }
  .page-loader .txt {
    color: #666;
    text-align: center;
    top: 40%;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-weight: bold;
    line-height: 1.5;
  } */
  /* SPINNER ANIMATION */
  .spinner {
    position: relative;
    top: 35%;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    background-color: #187cab;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
  }
  @-webkit-keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
  }
  .dropdown-menu.show {
      margin-top: -2px !important;
  }
  .integrationsCenter .col img {
      max-width: 100%;
  }
  .leftMenu ul li a.submenu:before {
    transform: rotate(45deg);
}
.leftMenu ul li a.submenu.collapsed:before {
    transform: rotate(-45deg);
}


  .btn-add{
      padding: 6px 0;
      background: var(--primary-color);
      border-radius: 50px;
      border: 1px solid var(--primary-color);
      height: 30px;
      width: 30px;
  }
  .btn-add img {
      height: 20px;
      width: 20px;
      vertical-align: -5px;
  }
  .btn-add:hover{
      background: var(--primary-color);
      border: 1px solid var(--primary-color);
      opacity: .6;
  }
  #excelFileInput{
      display: none;
  }
  .progress{
      border-radius: 0;
      height: 7px;
  }
  .dragImagesBoder .imgUpload{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
  }
  .viewFileModalMain .modal-lg{
      max-width: calc(100% - 30px);
  }
  
  .table .multiSelect .multiselect-container>li, 
  .table .multiSelect4 .multiselect-container>li {
      width: 100%;
  }
  /*
  .nav-tabs{
      border: 0;
  }
  .nav-tabs .nav-link{
      background: rgb(24 124 171 / 50%);
      border-radius: 0;
      border: 0;
      color: #fff;
      margin-right: 7px;
  }
  .nav-tabs .nav-item.show .nav-link, 
  .nav-tabs .nav-link.active{
      background: rgb(24 124 171);
      border-radius: 0;
      border: 0;
      color: #fff;
  }
  .nav-tabs .nav-link span{
      width: 100%;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
  }*/
  .ticketSystemMain .select2-container--default .select2-selection--single {
      background-color: #fff;
      border: 1px solid #D1D1D1;
      border-radius: 7px;
      color: #726F6E;
      padding: 0;
      font-size: 14px;
      height: 44px;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 44px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
  }
  .ticketSystemMain .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: var(--table-text);
      line-height: 22px;
  }
  .ticketSystemMain .select2-container--default .select2-selection--single .select2-selection__clear {
      margin-right: 10px;
  }
  .ticketSystemMain .select2-container{
      width: 100% !important;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
      content: "";
      border-top: 0;
      border-right: 2px solid #383e44;
      border-bottom: 2px solid #383e44;
      border-left: 0;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 16px;
      top: 19px;
      transform: rotate(45deg);
  }
  
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      content: "";
      border-top: 0;
      border-right: 2px solid #383e44;
      border-bottom: 2px solid #383e44;
      border-left: 0;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 16px;
      top: 19px;
      transform: rotate(45deg);
  }
  .switches-container {
      width: 95px;
      position: relative;
      display: flex;
      padding: 0;
      position: relative;
      background: var(--primary-color);
      line-height: 2rem;
      border-radius: 3rem;
      margin-left: auto;
      margin-right: auto;
  }
  
  /* input (radio) for toggling. hidden - use labels for clicking on */
  .switches-container input {
      visibility: hidden;
      position: absolute;
      top: 0;
  }
  
  /* labels for the input (radio) boxes - something to click on */
  .switches-container label {
      width: 50%;
      padding: 0;
      margin: 0;
      text-align: center;
      cursor: pointer;
      color: #fff;
  }
  
  /* switch highlighters wrapper (sliding left / right) 
      - need wrapper to enable the even margins around the highlight box
  */
  .switch-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      padding: 0.15rem;
      z-index: 3;
      transition: transform .5s cubic-bezier(.77, 0, .175, 1);
      /* transition: transform 1s; */
  }
  
  /* switch box highlighter */
  .switch {
      border-radius: 3rem;
      background: #fff;
      height: 100%;
  }
  
  /* switch box labels
      - default setup
      - toggle afterwards based on radio:checked status 
  */
  .switch div {
      width: 100%;
      text-align: center;
      opacity: 0;
      display: block;
      color: var(--primary-color) ;
      transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
      will-change: opacity;
      position: absolute;
      top: 0;
      left: 0;
  }
  
  /* slide the switch box from right to left */
  .switches-container input:nth-of-type(1):checked~.switch-wrapper {
      transform: translateX(0%);
  }
  
  /* slide the switch box from left to right */
  .switches-container input:nth-of-type(2):checked~.switch-wrapper {
      transform: translateX(100%);
  }
  
  /* toggle the switch box labels - first checkbox:checked - show first switch div */
  .switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
      opacity: 1;
  }
  
  /* toggle the switch box labels - second checkbox:checked - show second switch div */
  .switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
      opacity: 1;
  }
  
  .ruleSet .modal-dialog{
      max-width: 80%;
  }
  
  .excel-table {
    border-collapse: collapse;
    border: 1px solid #ddd;
    width: 100%;
  }
  
  .header-cell {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
    background-color: #f0f0f0;
  }
  
  .header-cell:first-child,
  .excel-table th:first-child,
  .excel-table td:first-child {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .excel-table th,
  .excel-table td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
  }
  
  .excel-table td[contenteditable="true"]:hover {
    background-color: #f0f0f0;
  }
  .dropdown-menu.show{
      max-height: 270px;
      overflow-y: auto;
      overflow-x: hidden;
  }
  
  .tooltip {
      padding: 0;
  }
  .tooltip-inner ul {
      padding-left: 17px;
      text-align: left;
      margin-bottom: 0;
  }
  .tooltip-inner {
      max-width: 300px;
  }
  .accordion-button:not(.collapsed){background-color:#187cab; color: #FFF;box-shadow:none;}
  .accordion-button:focus{box-shadow:none;}
  .accordion-button:not(.collapsed)::after{background-image: none;content: "\f077";font-family: "Font Awesome 5 Free";font-weight: 900;transform: rotate(0deg);}
  .accordion-button::after{background-image: none;content: "\f078";font-family: "Font Awesome 5 Free";font-weight: 900;}
  
  .ticketSystemMain .modal-lg{
      max-width: calc(100% - 30px);
      margin: 15px;
  }
  
  .ticketSystem{
      display: flex;
      position: relative;
      width: 100%;
      transition: .2s;
      background-color: rgb(245, 245, 245);
  }
  .ticketSystem aside{
      min-width: 22%;
      width: 22%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100dvh - 40px);
      /* height: 100dvh; */
      overflow-x: hidden;
      overflow-y: auto;
      background-color: rgb(256, 256, 256);
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  }
  .ticketSystem main{
      width: 100%;
      position: relative;
      right: 0;
      z-index: 0;
      display: inline-block;
      padding: 15px;
      overflow-x: hidden;
      overflow-y: auto;
      /* height: calc(100dvh - 40px); */
      height: 100dvh - 40px;
  }
  .ticketSystemMain .btn-close{
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 77;
      width: 0.5em;
      height: 0.5em;
      }
  .selectProperty{
      width: 100%;
      padding: 0;
      border-bottom: 1px solid var(--active-color);
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  }
  .selectProperty .form-select {
      border-radius: 0;
      border: 0;
      color: var(--primary-color);
      font-size: 1rem;
  }
  .ticketSystem aside ul{
      width: 100%;
      padding: 0;
      list-style: none;
      margin: 0;
  }
  .ticketSystem aside ul li{
      width: 100%;
  }
  .ticketSystem aside ul li a{
      width: 100%;
      padding: 10px;
      border: 0 !important;
      border-bottom: 1px solid #D1D1D1 !important;
      border-left: 5px solid transparent !important;
      border-radius: 0 !important;
  }
  .ticketSystem aside ul li a.active{
      border: 0 !important;
      border-bottom: 1px solid #D1D1D1 !important;;
      background-color: var(--active-select) !important;
      border-left: 5px solid var(--active-color) !important;
  }
  .ticketSystem aside ul li div{
      font-size: 15px;
      color: #000;
  }
  .ticketSystem aside ul li div span{
      color: var(--primary-color);
      font-weight: 500;
  }
  .ticketSystem aside ul li p{
      color: #979797;
      margin-bottom: 0;
      font-size: 12px;
  }
  .ticketSystem aside .selectPropertyTickets{
      height: calc(100dvh - 40px);
      width: 100%;
      position: relative;
      display: inline-block;
      overflow-x: hidden;
      overflow-y: auto;
  }
  .ticketSystem aside ul li.activeTicket{
      background-color: var(--active-select);
      border-color: var(--active-color);
  }
  .leftTicketList {
      width: 100%;
      padding: 0;
      border-bottom: 1px solid var(--active-color);
      -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
      width: 100%;
      padding: 10px;
  }
  .leftTicketList h3 {
      font-size: 18px;
      font-weight: 600;
      color: var(--primary-color);
      margin: 5px 8px;
  }
  .col-status{
      max-width: 110px;
  }
  .col-status i{
      font-style: normal;
  }
  .ticketSystem main .select2-container--default .select2-selection--single {
      height: 36px;
  }
  .ticketSystem main .select2-container--default .select2-selection--single .select2-selection__arrow {
      height: 36px;
  }
  .ticketSystem main .select2-container--default .select2-selection--single .select2-selection__rendered {
      line-height: 15px;
  }
  .ticketSystem main h2 {
      font-size: 18px;
      color: var(--body-text-color);
  }
  .ticketSystem main h2 span{
      color: var(--primary-color);
  }
  .dateBox{
      color: #b5b5b5;
      font-size: 14px;
  }
  .ticketSystem main .card h3 {
      font-size: 18px;
      font-weight: bold;
      color: var(--primary-color);
      line-height: 1;
  }
  .ticketSystem main .card h4 {
      font-size: 16px;
      font-weight: normal;
      color: var(--body-text-color);
      line-height: 1;
  }
  .ticketSystem main .card .card-header h4:after {
      display: inline-block;
      margin-left: 0;
      vertical-align: 0;
      content: "";
      border-top: 0;
      border-right: 2px solid var(--body-text-color);
      border-bottom: 2px solid var(--body-text-color);
      border-left: 0;
      width: 12px;
      height: 12px;
      position: absolute;
      right: 16px;
      top: 14px;
      transform: rotate(225deg);
  }
  .ticketSystem main .card .card-header.collapsed h4:after{
      top: 7px;
      transform: rotate(45deg);
  }
  .ticketSystem main .card .card-header {
      position: relative;
  }
  .commentTitle{
      font-weight: bold;
  }
  .commentTime {
      color: #b5b5b5;
      font-size: 14px;
  }
  .ticketSystem main .card hr {
      border-color: #f1f1f1;
      opacity: .8;
  }
  .comment .card{
    background: #f6f6f6;
   }
  .ticketSystem main .ticketMain{
      height: calc(100dvh - 260px);
      overflow-y: auto;
      width: calc(100% + 30px);
      margin: 0 -15px;
      padding: 15px;
  }
  .ticketSystemMain .multiSelect .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0;
      vertical-align: 0;
      content: "";
      border-top: 0;
      border-right: 2px solid #383e44;
      border-bottom: 2px solid #383e44;
      border-left: 0;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 16px;
      top: 12px;
      transform: rotate(45deg);
  }
  .ticketSystemMain .multiSelect .btn-group>.btn {
      border-radius: 7px;
      border-color: #D1D1D1;
      background-color: #fff;
      color: #726F6E;
      padding: 5px 10px;
      font-size: 14px;
      width: 100%;
      text-align: left;
  }
  .ticketSystemMain .multiSelect .multiselect-container>li, 
  .ticketSystemMain .multiSelect4 .multiselect-container>li, 
  .multiSelect5 .multiselect-container>li {
      display: inline-block;
      width: 100%;
  }
  .bg-primary {
      background-color: var(--primary-color)!important;
  }
  .bg-brown{
      background: rgb(185, 152, 151);
  }
  .ticketSystem main .ticketMain .btn-secondary {
      min-width: 36px;
  }
  .badge {
      font-style: normal !important;
  }
  .viewFileModalMain .table-responsive {
      min-height: 400px;
      height: calc(100dvh - 320px);
  }
  .btn-replay{
      position: absolute;
      right: 0;
      top: 0;
  }
   #replyBox {
      display: none; /* Initially hide the element */
  }
  .ruleSet .modal-body {
      background: rgb(245, 245, 245);;
  }
  .ruleSetRow{
      margin: 0 -15px;
  }
  .commentTitle .text-secondary {
      color: #b5b5b5 !important;
      font-weight: normal;
  }
  .editError{
      position: relative;
      padding-right: 45px !important;
  }
  .editError input,
  .editError .submitIcon{
      display: none;
  }
  
  .editError a{
      position: absolute;
      right: 8px;
      top: 7px;
  }
  .editError input{
      padding: 0px;
      border: 0;
      font-size: 18px;
  }
  .editError.border{
      border: 2px solid var(--primary-color) !important;
      z-index: 77;
      padding: calc(0.5rem - 2px);
  }
  .addfundview > .card{
      border: 1px solid var(--primary-color);
      border-top-left-radius: 0;
  }
  .commonTab .nav-tabs .nav-link{
      min-width: 146px;
      background-color: #fff;
      border-color: var(--primary-color);
      color: var(--primary-color);
      border-radius: 8px  8px 0 0;
      padding: 9px 15px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: -2px;
  }
  .commonTab .nav-tabs .nav-link.active{
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: #fff;
  }
  .commonTab .nav-tabs{
      border: 0;
  }
  .commonTab > .card {
      border: 1px solid var(--primary-color);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }
  .tabScroll{
      height: calc(100dvh - 426px);
      overflow: auto;
      min-height: 500px;
      padding-right: 12px !important;
  }
  
  
  /*body{
    background-color: #fafafa;
  }
  table.dataTable {
      background: #fff;
      border: 1px solid #CCCCCC;
  }*/
  /* Style for the autosuggest dropdown */
    .suggestions-dropdown {
      position: absolute;
      width: 98%;
      max-height: 150px;
      overflow-y: auto;
      border: 1px solid #ccc;
      background-color: #fff;
      z-index: 1000;
    }
  
    /* Style for individual suggestion items */
    .suggestions-dropdown div {
      padding: 5px 10px;
      cursor: pointer;
      font-size: 14px;
    }
  
    /* Highlight suggestion items on hover */
    .suggestions-dropdown div:hover {
      background-color: #f0f0f0;
    }
  .ticketSystem .select2-container--default .select2-selection--single .select2-selection__arrow b {
      top: 14px;
  }
  /* .ticketSystem .btn-primary {
      padding: 12px 15px;
  } */
  
  .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 24px;
  }
  
  .switch input {
      display: none;
  }
  
  .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc; /* Default color for the switch */
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;
  }
  
  .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
  }
  
  .switch input:checked + .slider {
      background-color: #2196F3; /* Color when the switch is on */
  }
  
  .switch input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
  }
  .btn-mapping-save{
      margin-bottom: -2px;
      margin-top: -2px;
  }
  .fund #fund{
      margin-top: 4px !important;
  }
  
  
  .can-toggle {
    position: relative;
    width: 80px;
    display: inherit;
  }
  .can-toggle *, .can-toggle *:before, .can-toggle *:after {
    box-sizing: border-box;
  }
  .can-toggle input[type=checkbox] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  .can-toggle input[type=checkbox][disabled] ~ label {
    pointer-events: none;
  }
  .can-toggle input[type=checkbox][disabled] ~ label .can-toggle__switch {
    opacity: 0.4;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    content: attr(data-checked);
  }
  .can-toggle label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
  }
  .can-toggle label .can-toggle__label-text {
    flex: 1;
    padding-left: 32px;
  }
  .can-toggle label .can-toggle__switch {
    position: relative;
  }
  .can-toggle label .can-toggle__switch:before {
    content: attr(data-checked);
    position: absolute;
    top: 0;
    text-transform: uppercase;
    text-align: center;
  }
  .can-toggle label .can-toggle__switch:after {
    content: attr(data-unchecked);
    position: absolute;
    z-index: 5;
    text-transform: uppercase;
    text-align: center;
    background: white;
    transform: translate3d(0, 0, 0);
  }
  .can-toggle input[type=checkbox][disabled] ~ label {
    color: rgba(234, 0, 48, 0.5);
  }
  .can-toggle input[type=checkbox]:focus ~ label .can-toggle__switch, .can-toggle input[type=checkbox]:hover ~ label .can-toggle__switch {
    background-color: #ea0030;
  }
  .can-toggle input[type=checkbox]:focus ~ label .can-toggle__switch:after, .can-toggle input[type=checkbox]:hover ~ label .can-toggle__switch:after {
    color: #b70026;
  }
  .can-toggle input[type=checkbox]:hover ~ label {
    color: #d1002b;
  }
  .can-toggle input[type=checkbox]:checked ~ label:hover {
    color: #55bc49;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch {
    background-color: #70c767;
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    color: #4fb743;
  }
  .can-toggle input[type=checkbox]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type=checkbox]:checked:hover ~ label .can-toggle__switch {
    background-color: #5fc054;
  }
  .can-toggle input[type=checkbox]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type=checkbox]:checked:hover ~ label .can-toggle__switch:after {
    color: #47a43d;
  }
  .can-toggle label .can-toggle__label-text {
    flex: 1;
  }
  .can-toggle label .can-toggle__switch {
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    background: #ff0538;
  }
  .can-toggle label .can-toggle__switch:before {
    color: rgba(255, 255, 255, 0.5);
  }
  .can-toggle label .can-toggle__switch:after {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    color: #ea0030;
  }
  .can-toggle input[type=checkbox]:focus ~ label .can-toggle__switch:after, .can-toggle input[type=checkbox]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
  }
  .can-toggle input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(calc(100% - 4px), 0, 0);
  }
  .can-toggle input[type=checkbox]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type=checkbox]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
  }
  .can-toggle label {
    font-size: 14px;
  }
  .can-toggle label .can-toggle__switch {
    height: 26px;
    flex: 0 0 100%;
    border-radius: 4px;
  }
  .can-toggle label .can-toggle__switch:before {
    left: 50%;
    font-size: 12px;
    line-height: 27px;
    width: 50%;
    padding: 0 12px;
  }
  .can-toggle label .can-toggle__switch:after {
    top: 2px;
    left: 2px;
    border-radius: 2px;
    width: 50%;
    line-height: 22px;
    font-size: 12px;
  }
  .can-toggle label .can-toggle__switch:hover:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
  }
  .can-toggle.can-toggle--size-small input[type=checkbox]:focus ~ label .can-toggle__switch:after, .can-toggle.can-toggle--size-small input[type=checkbox]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }
  .can-toggle.can-toggle--size-small input[type=checkbox]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(44px, 0, 0);
  }
  .can-toggle.can-toggle--size-small input[type=checkbox]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.can-toggle--size-small input[type=checkbox]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }
  .can-toggle.can-toggle--size-small label {
    font-size: 13px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch {
    height: 28px;
    flex: 0 0 90px;
    border-radius: 2px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch:before {
    left: 45px;
    font-size: 10px;
    line-height: 28px;
    width: 45px;
    padding: 0 12px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch:after {
    top: 1px;
    left: 1px;
    border-radius: 1px;
    width: 44px;
    line-height: 26px;
    font-size: 10px;
  }
  .can-toggle.can-toggle--size-small label .can-toggle__switch:hover:after {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }
  .editChart .select2-container{
      width: 100% !important;
  }
  body.paddingTop{
      padding-top: 26px;
  }
  body.paddingTop{
      margin-top: 9px !important;
  }
  
  
  .notification .dropdown-menu{
      right: 10px;
      top:calc(100% + 5px);
      left: inherit;
      border: 0;
      background: var(--primary-color);
      max-width: 400px;
      width: calc(100% - 20px) ;
      border-radius: 7px;
  }
  .notification .dropdown-menu a{
      color: #fff;
      text-decoration: none;
  }
  .notificationBox{
      background: rgba(0, 0, 0, 0.3);
      border-radius: 7px;
      position: relative;
  }
  .notificationBox h4{
      color: #fff;
      margin-bottom: 0;
  }
  .notificationBox p{
      color: #fff;
      margin-bottom: 0;
  }
  .notificationBox p.notificationDate{
      color: #fff;
      margin-bottom: 0;
      opacity: .5;
  }
  
  .notification h5 {
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 7px;
  }
  .notification .btn-close{
      position: absolute;
      color: #fff;
      top:10px;
      right: 10px;
      height: 5px;
      width: 5px;
      background:transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat ;
  }
  .notification .notificationBox .btn-close {
      height: 3px;
      width: 3px;
  }
  .notification .overflow-auto {
      overflow: auto!important;
      max-height: 400px;
  }
  .main .notificationBox h4 {
      color: var(--primary-color);
      margin-bottom: 0;
      font-size: 18px;
  }
  .main .notificationBox p {
      color: var(--body-text-color);
      margin-bottom: 0;
      font-size: 14px;
  }
  .main .notificationBox p.notificationDate {
      opacity: 0.5;
  }
  
  /*.uploadModalFeq .modal-lg,
  .uploadModalFundFeq .modal-lg {
      --bs-modal-width: 900px;
  }*/
  
  .timeBox .multiselect-item.multiselect-all,
  .timeBox1 .multiselect-item.multiselect-all,
  .ticketSystemMain .mappingTable .multiselect-item.multiselect-all{
      display: none;
  }
  .ticketSystemMain .mappingTable .form-select {
      padding: 5px 2.25rem 5px 12px;
  }
  
  .modal-backdrop + .modal-backdrop {
      z-index: 1055;
  }
  .addNewType {
      z-index: 1056;
  }
  .commonTab .nav-tabs .nav-link.active svg path{
      fill: #fff !important;
  }
   .file-input-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
  }
  
  .file-input-wrapper input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      z-index: 777;
  }
  
  .file-input-wrapper .btn {
      padding: 10px 20px;
      cursor: pointer;
      border: none;
  }
  
  .file-input-label {
      margin-left: 10px;
  }
  .file-input-wrapper .w-100 {
      width: 100%!important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  
  .multiDataSet .select2-container--default .select2-selection--multiple{
      border-radius: 7px;
      border-color: #D1D1D1;
      color: #726F6E;
      padding: 7px 12px;
      font-size: 1rem;
  }
  #property-view .form-control,
  #COAmapping_wrapper .form-control {
      padding: 5px 12px;
  }
  
  .form-control::placeholder {
    opacity: .5; /* Firefox */
  }
  
  .form-control::-ms-input-placeholder { /* Edge 12-18 */
    opacity: .5;
  }
  .noteText{
      font-size: 15px;
  }
  table.dataTable {
      width: 99.2% !important;
  }
  
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
      margin-top: 3px;
  }
  .dropdown-item {
      font-size: 13px;
  }
  /*--Main End--*/
  .commonTab .nav-tabs{
    flex-wrap: inherit;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}
.tabs-wrapper {
    display: flex;
    align-items: center;
  }
  
  .scroller-btn {
    cursor: pointer;
    font-size: 20px;
    margin: 0 10px;
    user-select: none;
  }
  
  .tabs-scroll-container {
    overflow: auto;
    flex: 1;
  }
  
  .nowrap {
    white-space: nowrap;
  }
  [class*="css-"][class*="-control"] {
    border-radius: 7px;
    border-color: #D1D1D1;
  }
  [class*="css-"][class*="-ValueContainer"] {
    padding: 9px 8px;
  }
.sample-spreadsheets .container{
    max-width:unset;
    padding:0;
}
.ka-table {
    width: 99% !important;
}
.ka-paging-pages, .ka-paging-sizes {
    margin: 12px 0 !important;
    /* padding: 0 !important; */
}
.ka-paging-page-index, .ka-paging-size {
    user-select: none !important;
    padding: 7px !important;
    color: var(--primary-color) !important;
    line-height: .8 !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 0 !important;
    margin: 0 -1px 0 0 !important;
}
.ka-paging-page-index-active, .ka-paging-size-active {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    border-radius: 0 !important;
    color: #fff !important;
}
.ka-paging-page-index:first-child {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
}
.ka-paging-page-index:last-child {
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
}
.rmsc {
    --rmsc-h: 48px !important;
}
.rmsc .dropdown-container {
    border-radius: 7px !important;
    border-color: #D1D1D1 !important;
    color: #726F6E !important;
}
.wizard .nav-tabs {
    position: relative;
    margin: 0 auto;
    border:0 ;
}
.wizard .nav-tabs > li {
    max-width: 100%;
    flex: 1 0;
}

.wizard .nav-tabs > li button {
    width: 100%;
    margin: 0px auto 20px;
    border-radius: 0;
    padding: 0;
    border: 0;
    font-size: 18px;
    font-weight: 700;
    position: relative;
    height: 50px;
}
.wizard li button span {
    content: " ";
    width: 50px;
    height: 50px;
    margin: 0;
    border-radius: 100%;
    padding: 0;
    border: 3px solid #dee2e6;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 77;
    line-height: 2.4;
}
.wizard li button:before {
    content: " ";
    width: calc(100% - 25px);
    height: 1px;
    margin: 0;
    border-top: 3px solid #dee2e6;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -50%;
    transform: translate(1px, -50%);
}
.wizard li:first-child button:before{
    display: none;
}
.wizard .nav-tabs > li button.active span {
    border:2px solid var(--primary-color);
    color: var(--primary-color);
}

    .wizard .nav-tabs > li button:hover {
        background: transparent;
    }
    #menu svg rect{
        width:26px;
        transition: all .5s ease;
    }
    #menu svg rect#Rectangle_177{
        width:17px;
        transition: all .5s ease;
    }
    #menu:hover svg rect#Rectangle_177{
        width:26px;
    }
    .activeMenu #menu svg rect#Rectangle_177{
        display: none;
        transition: all .5s ease;
    }
    .activeMenu #menu svg rect#Rectangle_176 {
        transform: translate(8px,0) rotate(45deg);
    }
    .activeMenu #menu svg rect#Rectangle_175 {
        transform: translate(5px,18px) rotate(-45deg);    
    }
    .ticketSystem .form-select,
    .ticketSystem .form-control {
        padding: 6px 2.25rem 6px 12px;
        height: 36px;
        line-height: 1.2;
    }
    .ticketSystem .rmsc .dropdown-heading {
        height: 34px;
    }
    .table-height{
        min-height: 200px;
    }
    /***** style for progress bar ****/
.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%;
    position: relative;
    text-wrap: nowrap;
  }
  .step::before {
    content: '';
    display: inline-block;
    height: 2px;
    width: 100%;
    background-color: #ccc;
    position: absolute;
    left:50%;
    top: 14px;
    z-index: 77;
  }
  .step:last-child:before {
    display: none;
  }
  
  .step-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 78;
  }

  .line {
    height: 2px;
    flex: 1;
    margin-top: -18px;
    background-color: #ccc;
    display: none;
  }
  
  .step-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 105%;
  }
  .dropdown-menu ul{
    padding-left: 0;
  }
  .wizard .nav-tabs > li button.active{
    background-color: transparent;
  }
  .dashboard-widgets .card .widgets-content .widgets-label a{
    margin-left: 7px;
  }
  
  .card .pagination {
    align-items: center;
}
.card .pagination .mt-1 {
    margin-top: 0 !important;
}
.card .pagination input,
.card .pagination select {
    border-radius: 7px;
    border: 1px solid #D1D1D1;
    color: #726F6E;
    padding: 5px 13px;
    font-size: 1rem;
    height: 36px;
}
.pagination .tablepagination{
    display: flex;
    margin-right: 10px;
}
.pagination .tablepagination .page-link{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    margin-right: -1px;
}
.pagination .tablepagination .page-link:first-child{
    border-radius: 7px 0 0 7px;
}
.pagination .tablepagination .page-link:last-child{
    border-radius: 0 7px 7px 0;
}
header .badge.rounded-pill{
    width: 21px;
    margin-left: -14px;
}
.table tr td select + span{
    margin-left: 7px;
}
.ticketSystem aside ul li div span.bg-success,
.ticketSystem .tab-content .bg-success.text-dark,
.ticketSystem aside ul li div span.bg-brown{
    color: #fff !important;
}
.ticketSystem main .ticketMain .dropdown-item svg{
    width: 20px;
    margin-right: 7px;
}
.text-primary {
    --bs-text-opacity: 1;
    color: var(--primary-color) !important;
}
.modal-content .table tr th input{
    margin-left: 7px;
}
.form-check-label-right{
    margin-right: 50px;
}
.form-check-label-left{
    margin-left: 15px;
}
  /*---responsive start--*/
  @media (max-width:1366px){
      body {
          font-size: 14px;
      }
      .navbar-brand img {
          max-width: 105px;
      }
      #menu img{
          width: 20px;
      }
      /*.navbar-expand-lg .navbar-nav .nav-link img{
          width: 20px;
      }*/
      .userPhoto {
          height: 39px;
          width: 39px;
          font-size: 15px;
      }
      /*.leftMenu ul li a i img {
          height: 24px;
      }
      .leftMenu ul li a span {
          font-size: 16px;
          padding-left:5px;
      }
      .leftMenu > ul > li > a span{
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
      .leftMenu ul li a {
          padding: 8px 10px;
      }*/
      .dashboard-widgets .card i img {
          width: 25px;
      }
      .dashboard-widgets .card i{
          height: 40px;
          width: 40px;
          padding: 7px;
      }
      .dashboard-widgets .card .widgets-content {
          width: calc(100% - 15px);
      }
      .dashboard-widgets .card .widgets-content .widgets-amount {
          font-size: 35px;
      }
      .commonTab .nav-tabs .nav-link svg{
          height: 25px;
          width: 25px;
      }
      .table tr th, .table tr td {
          font-size: 14px;
          padding-top: 5px;
          padding-bottom: 5px;
      }
      .btn-view-all {
          font-size: 16px;
      }
      .main.p-4{
          padding: 15px !important;
      }
      hr {
          margin: 10px 0;
      }
      h1 {
          font-size: 26px;
      }
      .dropdown-item{
          font-size: 13px;
          padding: 1px 10px;
      }
      .dropdown-item svg,
      .dropdown-item img{
          width: 20px !important;
          margin-right: 0.25rem!important;
          height: 18px !important;
      }
      .mappingTable.table tr th, .mappingTable.table tr td {
          font-size: 14px;
      }
      .ka-table tr th, .ka-table tr td {
          font-size: 14px;
      }
      .btn-primary,
      .btn-secondary {
          padding: 5px 15px;
          font-size: 14px;
      }
      .mappingTable.table tr td img,
      .mappingTable.table tr td svg{
          width: 18px;
          height: 18px;
      }
      .ka-table tr td img,
      .ka-table tr td svg{
          width: 18px;
          height: 18px;
      }
      .btn-add {
          padding: 0px 0;
          height: 25px;
          width: 25px;
      }
      .mappingTable.table tr td .btn-add img,      
      .ka-table tr td .btn-add img{
          width: 16px;
          height: 16px;
          vertical-align: -2px
      }
      .ka-table tr td .btn-add img{
          width: 16px;
          height: 16px;
          vertical-align: -2px
      }
      .form-control,
      .form-select,
      .mappingTable .form-control, 
      .mappingTable .form-select,
      .ka-table .form-control, 
      .ka-table .form-select {
          font-size: 14px;
          padding-top: 5px;
          padding-bottom: 5px;
      }
      .btn-close{
          width: 0.75em;
          height: 0.75em;
      }
      .modal-header,
      .modal-footer{
          padding-top: 9px;
          padding-bottom: 9px;
      }
      .col-form-label {
          padding-bottom: 3px;
      }
      .mb-3 {
          margin-bottom: 0.75rem!important;
      }
      .modal h5 {
          font-size: 24px;
      }
      .fund #fund {
          margin-top: 10px !important;
      }
      .multiSelect .btn-group>.btn{
          font-size: 14px;
          padding-top: 5px;
          padding-bottom: 5px;
      }
      .multiSelect .dropdown-toggle::after {
          top: 10px;
      }
      .multiSelect .multiselect-container>li>a>label {
          font-size: 14px;
      }
      .dashboard-widgets .card-body {
          padding: 7px 1rem;
      }
      .dashboard-widgets .mb-2 {
          margin-bottom: 0rem!important;
      }
      .switch {
          width: 30px;
          height: 15px;
      }
      .slider:before {
          height: 11px;
          width: 11px;
          left: 3px;
          bottom: 2px;
      }
      .switch input:checked + .slider:before {
          -webkit-transform: translateX(13px);
          -ms-transform: translateX(13px);
          transform: translateX(13px);
      }
      .widgets-label{
          font-size: 13px;
      }
      .select2-container--default .select2-selection--single {
          height: 33px;
      }
      .select2-container--default .select2-selection--single .select2-selection__rendered {
          line-height: 19px;
          padding: 7px 12px;
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow {
          height: 31px;
      }
      .select2-container--default .select2-selection--single .select2-selection__arrow b {
          top: 12px;
      }
      .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
          top: 12px;
      }
      [class*="css-"][class*="-ValueContainer"] {
        padding: 2px 8px;
    }
      [class*="css-"][class*="-control"] {
            min-height: 33px;
        }
        [class*="css-"][class*="-indicatorContainer"] {
            padding: 6px 8px;
        }
        
    .rmsc {
        --rmsc-h: 31px !important;
    }
    .leftMenu ul li a.submenu span:after {
        top: 26px;
    }
    .leftMenu ul li a.submenu.collapsed span:after {
        top: 19px;
    }
    .activeMenu .leftMenu > ul > li > a.submenu.collapsed span:after {
        top: 14px;
    }
    .leftMenu ul ul li a.submenu.collapsed span:after {
        top: 12px;
    }
    .leftMenu ul ul li a.submenu span:after {
        top: 18px;
    }
    .activeMenu .leftMenu ul li a.submenu span:after {
        top: 18px;
    }
    .activeMenu .leftMenu > ul > li:hover > a.submenu span:after {
        top: 20px;
        transform: rotate(225deg);
    }
    .activeMenu .leftMenu > ul ul > li:hover > a.submenu span:after {
        top: 12px;
        transform: rotate(225deg);
    }
    }
  @media (max-width:1365px){
      .dashboard-widgets .col{
          min-width: 33.33%;      
          max-width: 33.33%;        
      }
  }
  @media (max-width:991px){
      .dashboard-widgets .col{
          min-width: 100%;      
          max-width: 100%;        
      }
      .activeMenu{
        overflow: hidden;
      }
      .leftMenu {
          /* width: 65px; */
          position: fixed;
          left: -250px;
          width: 250px;
          transition: all .5s ease;
      }
      .activeMenu .main,
      .main {
          /* width: calc(100% - 65px); */
          width: 100%;
      }
    .activeMenu .leftMenu {
        width: 250px;
        left: 0px;
        min-height: calc(100dvh - 63px);
        overflow: auto;
        max-height: calc(100dvh - 63px);
        height: calc(100dvh - 63px);
        transition: all .5s ease;
        margin-left: 0;
    }
      .table tr th, .table tr td {
          white-space: nowrap;
      }
      .btn-labeled.btn-primary {
          font-size: 18px;
          padding-right: 10px;
      }
      .btn-label {
          padding: 1px 0;
          height: 35px;
          width: 35px;
          margin-right: -7px;
      }
      .btn-label img{
          height: 20px;
      }
      .dataTables_wrapper .btn-group .btn-group {
          right: 0px;
          top: -53px;
      }
      div.dataTables_wrapper div.dataTables_filter input {
          width: 140px;
      }
      .table tr th, .table tr td {
          font-size: 16px;
      }
      .multiSelect .multiselect-container>li,
      .multiSelect3 .multiselect-container>li {
          width: 100%;
      }
      .modal h5 {
          font-size: 24px;
      }
      .btn-primary,
      .btn-secondary {
          padding: 6px 10px;
      }
      .form-control,
      .multiSelect .btn-group>.btn,
      .form-select {
          padding-top: 6px;
          padding-bottom: 6px;
      }
      .multiSelect .dropdown-toggle::after {
          width: 10px;
          height: 10px;
          right: 14px;
          top: 12px;
      }
      .mb-3 {
          margin-bottom: 0.75rem!important;
      }
      .navbar-expand-lg .navbar-collapse {
          display: flex!important;
          flex-basis: auto;
      }
      .navbar-expand-lg .navbar-nav {
          flex-direction: row;
      }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
          position: absolute;
      }
      h1 {
          font-size: 26px;
      }
      .p-4 {
          padding: 1rem!important;
      }
  
      .dashboard-widgets .mb-2 {
          margin-bottom: .5rem!important;
      }
    .col-100 {
        min-width: 100%;
    }
    .ticketSystem main .row .col-lg-3{
        margin-bottom: 10px;
    }
    .datasetContent .col-12 .col-auto {
        width: 100%;
    }
    .datasetContent .col-auto .col-form-label{
        display: none;
    }
  }
  @media (max-width:767px){
      .login-main {
          max-width: 400px;
          padding: 25px 25px;
      }
      div.dt-buttons {
          width: auto;
          text-align: left;
      }
      .integrationsCenter .col {
          min-width: 50%;
      }
      .dashboardTab.commonTab .nav-tabs .nav-link{
        min-width: auto;
      }
      .dashboardTab.commonTab .nav-tabs .nav-link span{
        display: none;
      }
      .dashboardTab.commonTab .nav-tabs .nav-link svg{
        margin-right: 0 !important;
      }
      .ticketSystem aside{
        min-width: 100%;
        width: 100%;
        height: auto;
      }
      .ticketSystem aside .selectPropertyTickets {
        height: auto;
    }
    .ticketSystem {
        flex-wrap: wrap;
    }
    .ticketSystem main {
        overflow-x: visible;
        overflow-y: visible;
        height: auto;
        position: initial;
    }
    .ruleSet .modal-dialog {
        max-width: calc(100% - 1rem);
    }
  }
  
  @media (max-width:575px){
      .dataTables_wrapper .row:first-child .col-sm-8,
      .dataTables_wrapper .row:first-child .col-sm-4{
          text-align: center !important;
      }
      .navbar-brand img {
          max-width: 90px;
          width: 100%;
      }
      .integrationsCenter .col {
          min-width: 100%;
      }
      .userDetails .userEmail {
        max-width: 60px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .navbar-brand{
        margin-right: 10px;
    }
    .userPhoto{
        margin-right: 5px;
    }
  
  }
  
  
  /*---responsive end--*/
  
  
  
  
  
  .menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 300ms ease;
    height: 26px;
    width: 26px;
  }
  .menu-toggle span {
    height: 4px;
    width: 26px;
    background-color: #000;
    transition: width 200ms ease;
  }
  .menu-toggle span:nth-child(1) {
    width: 80%;
    transition-delay: 200ms;
  }
  .menu-toggle span:nth-child(2) {
    width: 100%;
  }
  .menu-toggle span:nth-child(3) {
    width: 60%;
    transition-delay: 400ms;
  }
  .menu-toggle span:nth-child(4) {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 10px;
    height: 0;
    transition: height 400ms;
  }
  
  .activeMenu .menu-toggle {
    transform: translate(-50%, -50%) rotate(45deg);
    transition-delay: 400ms;
  }
  .activeMenu .menu-toggle span:nth-child(1) {
    width: 0;
    transition: 200ms ease;
  }
  .activeMenu .menu-toggle span:nth-child(3) {
    width: 0;
    transition: 200ms ease;
    transition-delay: 150ms;
  }
  .activeMenu .menu-toggle span:nth-child(4) {
    height: 26px;
    width: 4px;
    transition: height 200ms ease;
    transition-delay: 200ms;
  }
  
  .multiDataSet .select2{
      width: 100% !important;
      position: relative !important;
  }
  .editOption{
      display: none !important;
  }
  .editTr .editOption{
      display: block !important;
  }
  .editTr .viewOption{
      display: none !important;
  }
  
  .selectPropertyTickets .border {
  /*    border: 4px solid var(--primary-color) !important;*/
  }
  .selectPropertyTickets h6{
      font-size: 18px;
      font-weight: 700;
  }
  .collapsed {
      border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
  }
  .carousel-prev,
  .carousel-next{
      position: relative;
      z-index: 777;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
      width: 1.4rem;
      height: 1.4rem;
  }
  .carousel-control-next-icon{
      vertical-align: middle;
      margin-right: -17px;
  }
  .carousel-control-prev-icon {
      vertical-align: middle;
      margin-left: -17px;
  }
  .btn.active .carousel-control-next-icon, 
  .btn:first-child:active .carousel-control-next-icon, 
  :not(.btn-check)+.btn:active .carousel-control-next-icon{
      background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23187cab'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
      }
  .carousel-inner {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-top: -37px;
  }
  .carousel-inner h3 {
      color:var(--primary-color);
      text-align: center;
      }
  .carousel-inner .commonTab{
      margin-top: 25px;
  }
  #suggestions.list-group{
      position: absolute;
      width: calc(100% - 30px);
  }
  #selectedTags .badge{
      margin: 2px;
  }
  #selectedTags .btn-close {
      background:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") ;
      width: 0.5em;
      height: 0.5em;
      margin-left: 5px;
  }
  
  .expandMain{
      overflow: hidden;
  }
  .expandMain .main{
      position: fixed;
      /*    background: #fff;*/
      z-index: 7777;
      top: 0;
      left: 0;
      width: 100%;
      height: 100dvh;
      overflow: auto;
      margin: 0;
  }
  .expandMain .tabScroll {
      height: calc(100dvh - 336px);
      overflow: auto;
      min-height: 500px;
      padding-right: 12px !important;
  }



  .table>:not(:last-child)>:last-child>* {
    border-bottom-color: #ccc;
}


.card-container {
    display: flex;
    flex-direction: column;
  }
  
  .card-spacing {
    margin-bottom: 16px; /* Adjust the spacing as needed */
  }

  .dataModelTable{
width: 100%;
max-height: 630px;
display: inline-block;
overflow: auto;

  }

  .no-click {
    pointer-events: none;
  }

  /* Spinner.css */

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999 !important; /* Make sure the spinner is on top of other elements */  
  }
  
  /* .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  } */
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .editticketSystem .btn-close {
    right: 15px;
   top: 12px;
}


.page-loader {
    width: 100%;
    height: 100dvh;
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    z-index: 100000;
    top: 0;
    left: 0;
}
.page-loader .txt {
  color: #666;
  text-align: center;
  top: 50%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
  transform: translate(0, -50%);
}
.loader {
  position: relative;
  top: calc(50% - 10px);
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 100%;
  transform: translate(0, -50%);
}

#loader-1:before, #loader-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #3498db;
} 
#loader-1:before{
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after{
  border: 10px solid #ccc;
}

@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.arabic{
    direction: rtl;
}
/* .arabic .leftMenu {
    width: 290px;
} */
.arabic .activeMenu .leftMenu {
    margin-right: -250px;
    margin-left: 0;
}
.arabic .dashboard-widgets .card .widgets-content {
    text-align: left;
}
.arabic .dashboard-widgets .card i {
    margin: 0 -30px 0 0;
}
.arabic .userArrow {
    margin-left: 0px;
    margin-right: 10px;
}
.arabic .userPhoto {
    margin-left: 12px;
    margin-right: 0px;
}
.arabic header .badge.rounded-pill {
    width: 21px;
    margin-left: 0px;
    margin-right: -14px;
    padding: .20rem;
    text-align: center;
}
.arabic .form-select,
.arabic .form-control{
    text-align: right;
}
.arabic .form-select {
    padding-left: 2.25rem;
    padding-right: 12px;
}
.arabic .language-dropdown .form-select {
    padding: 0.25rem 0.75rem 0.25rem 2.25rem;
    background-position: left .75rem center;
}
.arabic .language-dropdown .form-select {
    padding-left: 2.25rem;
    padding-right: 0.75rem;
    background-position: left .75rem center;
}

.arabic .dashboard-widgets .card .widgets-content .widgets-label a {
    margin-right: 7px;
    margin-left: 0px;
}
.arabic .main-sidebar .nav-item.has-submenu a:before {
    margin-right: 0;
    margin-left: 10px;
}
.arabic .commonTab .nav-tabs .nav-link {
    margin-right: 0;
    margin-left: 10px;
}
.arabic .btn-view-all:after {
    margin-left: 0;
    border-width: 7.5px 10.6px 7.5px 0;
    border-color: transparent var(--primary-color) transparent transparent;
    transform: rotate(0deg);
    margin-right: 7px;
}
.arabic .pagination .tablepagination .page-link:last-child {
    border-radius: 7px 0 0 7px;
    margin-left: 10px !important;
    margin-right: 0 !important;
}
.arabic .pagination .tablepagination .page-link {
    margin-left: -1px;
    margin-right: 0;
}
.arabic .pagination .tablepagination .page-link:first-child {
    border-radius: 0 7px 7px 0;
}
.arabic .table-search {
    background-repeat: no-repeat;
    background-position: calc(100% - 5px);
    padding-right: 31px;
}
.arabic .float-end {
    float: left !important;
}
.arabic .table>:not(:first-child) {
    border-top: 1px solid #CCCCCC;
}
.arabic .table>:not(:last-child)>:last-child>* {
    border-bottom-color: #ccc;
}
.arabic .table {
    border-top: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
}
.arabic .table tr th, 
.arabic .table tr td {
    border-bottom: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
}
.arabic .dashboard-widgets .card {
    margin-left: 0;
    margin-right: 15px;
}
.arabic .dropdown-item {
    text-align: right;
}
.arabic .addfundview > .card {
    border-top-left-radius: 7px;
    border-top-right-radius: 0;
}
.arabic .card .pagination input, 
.arabic .card .pagination select {
    text-align: right;
}

.arabic .funds-mapping ul li label span:before {
    border-width: 8.5px 11px 8.5px 0;
    border-color: transparent #fff transparent transparent ;
    margin-left: 10px;
}
.arabic .dataTables_wrapper .form-select, 
.arabic .page-item {
    padding: 5px 7px 5px 2.25rem;
    text-align: right;
}
.arabic .modal .modal-content .modal-header .btn-close {
    top: 20px;
    left: 20px;
    right: unset;
}
.arabic .ticketSystemMain .btn-close {
    right: unset;
    left: 5px;
}

.arabic .modal-content .table tr th input{
    margin-right: 7px;
}
.arabic .dropdown-menu ul {
    padding-right: 0;
}
.arabic .form-check-label-right{
    margin-left: 50px;
    margin-right: 0px;
}
.arabic .form-check-label-left{
    margin-left: 0px;
    margin-right: 15px;
}
.arabic .table tr td select + span {
    margin-right: 7px;
    margin-left: 0px;
}